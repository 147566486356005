import React, { useState } from "react";
import {
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  Alert,
  IconButton,
  Tooltip,
} from "@mui/material";
import Title from "../../components/Core/Title";
import axios from "axios";
import { useLoaderData, useNavigate } from "react-router-dom";
import FullWidthBannerImg from "../../components/Images/FullWidthBannerImg.js";
import { InlineWidget } from "react-calendly";
import { ContentCopy } from "@mui/icons-material";

export default function ContactUs() {
  const [alert, setAlert] = useState(undefined);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [question, setQuestion] = useState("");
  const loaderData = useLoaderData().loader1;
  const navigate = useNavigate();
  // console.log("contact us loader data", loaderData);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    axios
      .post("/api/contact/ci/", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRFToken": loaderData.csrf_token,
        },
      })
      .then((response) => {
        // console.log(response);
        setAlert({ question: "Successfully sent question" });
        alert.type = "success";
      })
      .catch((error) => {
        // console.log(error);
        error.response.data.type = "error";
        setAlert(error.response.data);
      })
      .finally(() =>
        setTimeout(() => {
          setAlert(undefined);
          setName("");
          setEmail("");
          setQuestion("");
        }, 5000),
      );
  };

  return (
    <>
      <Box
        sx={{ width: { xs: "85%", md: "80%", xl: "65%" }, margin: "2% auto" }}
      >
        {loaderData.banner !== null && (
          <FullWidthBannerImg
            alt={loaderData.banner.title}
            img_url={loaderData.banner.meta.download_url}
          />
        )}
        <Title
          text={loaderData.title !== null ? loaderData.title : "Contact Us"}
          color="text.main"
        />
        <Typography
          variant="body1"
          color="secondary"
          dangerouslySetInnerHTML={{ __html: loaderData.description }}
        ></Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
        >
          {/* Contact Info and Contact Form */}
          <Grid item xs={12} md={loaderData.calendly_url !== null ? 5 : 12}>
            <Box sx={{ overflow: "hidden" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                {/* Contact info */}
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    {loaderData.contact_name !== null && (
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {loaderData.contact_name}
                        </Typography>
                      </Grid>
                    )}
                    {loaderData.contact_phone !== null && (
                      <>
                        <Grid item xs={10}>
                          <Typography variant="h6">
                            <strong>Office:</strong> {loaderData.contact_phone}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Box textAlign="right">
                            <Tooltip
                              title="copy to clipboard"
                              placement="right"
                            >
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    loaderData.contact_phone,
                                  );
                                }}
                              >
                                <ContentCopy />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                      </>
                    )}
                    {loaderData.contact_fax !== null && (
                      <>
                        <Grid item xs={10}>
                          <Typography variant="h6">
                            <strong>Fax:</strong> {loaderData.contact_fax}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Box textAlign="right">
                            <Tooltip
                              title="copy to clipboard"
                              placement="right"
                            >
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    loaderData.contact_fax,
                                  );
                                }}
                              >
                                <ContentCopy />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                      </>
                    )}
                    {loaderData.contact_email !== null && (
                      <>
                        <Grid item xs={10}>
                          <Typography variant="h6">
                            <strong>Email:</strong> {loaderData.contact_email}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Box textAlign="right">
                            <Tooltip
                              title="copy to clipboard"
                              placement="right"
                            >
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    loaderData.contact_email,
                                  );
                                }}
                              >
                                <ContentCopy />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                {/* Contact form */}
                <Grid item xs={12}>
                  {alert !== undefined &&
                    Object.keys(alert).map(
                      (key) =>
                        key !== "type" && (
                          <Alert severity={alert.type}>
                            {alert[key]}
                          </Alert>
                        ),
                    )}
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                  >
                    <Typography variant="h6">Have a question?</Typography>
                    <TextField
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      error={alert !== undefined && alert.type === "error"}
                      margin="normal"
                      required
                      fullWidth
                      name="email"
                      label="email"
                      type="email"
                      id="email"
                      autoComplete="email"
                      variant="outlined"
                    />
                    <TextField
                      onChange={(e) => setQuestion(e.target.value)}
                      value={question}
                      error={alert !== undefined && alert.type === "error"}
                      margin="normal"
                      required
                      fullWidth
                      name="question"
                      label="question"
                      type="question"
                      id="question"
                      autoComplete="question"
                      rows={4}
                      multiline
                      variant="outlined"
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="filledGreen"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Submit Your Question
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {/* Calendly */}
          {loaderData.calendly_url !== null && (
            <Grid item xs={12} md={7}>
              <Box
                sx={{
                  textAlign: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <InlineWidget
                  url={loaderData.calendly_url}
                  styles={{ height: "600px" }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
        <br />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          sx={{
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "secondary.main",
          }}
        ></Grid>
      </Box>
    </>
  );
}

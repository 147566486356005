import React from "react";
import { createTheme } from "@mui/material";
import { orange } from "@mui/material/colors";

// Breakpoints!
// xs, extra-small: 0px or larger
// sm, small: 600px or larger
// md, medium: 960px or larger
// lg, large: 1280px or larger
// xl, extra-large: 1920px or larger

export const Theme = createTheme({
  palette: {
    type: "light",
    primary: {
      //turquoise
      main: "#164F5C",
      light: "#73959d",
      dark: "#07282F"
    },
    secondary: {
      main: "#731B36",
      dark: "#5B152A",
    },
    black: {
      main: "#231F20",
    },

    warning: {
      main: "#731B36",
      dark: "#5B152A",
      contrastText: "#3f3f3f",
    },
    info: {
      main: "#231F20",
    },
    success: {
      main: "#164F5C",
    },
    text: {
      primary: "#3f3f3f",
      secondary: "#505050",
      disabled: "#777777",
      hint: "#3f3f3f",
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
    error: {
      main: "#D48440",
      light: "#d89053",
      dark: "#CF762B",
      contrastText: "#3f3f3f",
    },
  },

  // Open Sans supports weights between 300 and 800
  typography: {
    fontFamily: "Open Sans, sans-serif",
    button: {
      fontWeight: 500,
      lineHeight: 2.6,
    },

    //H1
    h1: {
      fontFamily: "Crimson Pro Variable, sans-serif",
      fontsize: "2.5rem",
      color: "primary.main",
      letterSpacing: "0.7px",
    },
    // H2 is used for the Title component for Page titles
    h2: {
      fontFamily: "Crimson Pro Variable, sans-serif",
      fontWeight: "500",
      fontSize: "3rem",
      letterSpacing: "0.7px",
      color: "#3f3f3f", // primary.main
    },
    // H3 is used for the introductory paragraph on Home
    h3: {
      fontFamily: "Open Sans, sans-serif",
      color: "#3f3f3f",
      fontSize: "1.8rem",
      lineHeight: "2.1rem"
    },
    // H4 is used for content block sub headers (Home, About, etc.)
    h4: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 400,
      fontSize: "2.34rem",
      color: "#3f3f3f",
    },
    // H5
    h5: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 300,
      textTransform: "uppercase",
      fontSize: "2rem",
      letterSpacing: "0.25rem",
    },

    // h6 is used for the phone + fax + email on the Contact page
    h6: {
      fontFamily: "Open Sans, sans-serif",
      fontSize: "0.9rem",
      maxWidth: "100%",
      //these 3 properties are attempting to force the email to break to 2 lines
      overflowWrap: "break-word",
      textWrap: "pretty",
      display: "inline-block",

    },
    body1: {
      fontFamily: "Open Sans, sans-serif",
      color: "#3f3f3f",
      fontSize: "1.6rem",
      // letterSpacing: ".06rem",
      lineHeight: "2.3rem",
    },
    // Pull Quote is used for Video Descriptions on VideoPlayer.js
      pullquote: {
      color: "#3f3f3f",
      fontSize: "1.2rem",
    },
    // Caption is used for the copyright at the bottom of the page, as well as video duration
    caption: {
      textTransform: "capitalize",
      color: "#3f3f3f",
      fontSize: "0.9rem",
    },
    // Tag is used for the trio of adjectives used to categorize and describe videos.
    // Appears in VideoDesc.JS exclusively
    tag: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 400,
      fontSize: "1.1rem",
      color: "#ffffff",
    },
    // Used on the Bach Flowers Products page and Subscriptions
    price: {
      fontFamily: "Open Sans, sans-serif",
      color: "#164F5C", // primary.main
      fontWeight: 800,
      fontSize: "2rem",
      textAlign: "center",
    },
  },


  components: {
    // Inputs
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "0.9rem",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: "0.9rem",
        }
      }
    },
      //Buttons
    MuiButton: {
      styleOverrides: {
        root: {
          textAlign: "center",
          fontFamily: "Open Sans, sans-serif",
          transition: "0.4s ease all",
          fontWeight: "500",
          fontSize: "1.1rem",
          lineHeight: "2rem",
          "&:hover": {
            backgroundColor: "transparent",
            // this is necessary to override terrible default hover effects from Material
          },
          "&:active":{
            backgroundColor: "#164F5C",
          }
        },
      },
      variants: [
        //Logo button in the top left of the header
        {
          props: { variant: "LogoButton" },
          style: {
                // textTransform: "none", 
                // whiteSpace: "nowrap", 
                // color: "text.primary", 
                // fontSize: "1.5em", 
                // fontWeight: "500",
                // fontFamily: 'Crimson Pro Variable, sans-serif',
                // letterSpacing: '2px',
                // borderRadius: "none !important",
                // textAlign: {xs: "center", sm: "left"},
                // verticalAlign: "middle",
                // position: "static",
                // lineHeight: {xs: "2", sm: "2.6"},
                width: "100%",
                height: "100%",
                minHeight: "8vh",
                // Background Image URL is set inline in order to utilize breakpoints
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "left center",

          },
        },

        // Navigation Buttons in the Header
        {
          props: { variant: "inactiveNavButton" },
          style: {
            textTransform: "none",
            textAlign: "center",
            color: "text.main",
            fontSize: "1rem",
            lineHeight: "2.4",
            height: "100%",
            borderRadius: "0",
            "&:hover": {
              // set all color values to primary.main
              background: "rgb(22,79,92)",
              background:
                "linear-gradient(0deg, rgba(22,79,92,1) 12%, rgba(255,255,255,0) 12%)",
            },
            "&:hover span": {
              color: "#164F5C",
            },
          },
        },
        {
          props: { variant: "activeNavButton" },
          style: {
            textTransform: "none",
            textAlign: "center",
            color: "text.main",
            fontSize: "1rem",
            lineHeight: "2.4",
            height: "100%",
            borderRadius: "0",
            background:
              "linear-gradient(0deg, rgba(22,79,92,1) 12%, rgba(255,255,255,0) 12%)",
            span: {
              color: "#164F5C",
            },
          },
        },
        {
          props: { variant: "filledGreen" },
          style: {
            borderRadius: 0,
            border: "solid 3px transparent",
            display: "block",
            //borderImageOutset adjusts so that adding the border on hover doesn't change the size
            borderImageOutset: "3px",
            // backgroundImage:
            //   'url("/static/images/buttonBackgrounds/buttonFilledGreen.png")',
            // backgroundSize: "cover",
            // backgroundRepeat: "no-repeat",
            color: "white",
            backgroundColor: "#164F5C",
            padding: "10px",
            boxSizing: "border-box",
            width: "100%",
            textTransform: "capitalize",
            "&:hover": {
              // it is impossible to add transition effects to borderimages
              background: "linear-gradient(to right, #0e5352, #2d918d)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              display: "block",
              borderImage: "linear-gradient(to right, #0e5352, #2d918d) 1",
            },
          },
        },
        // Text Links Buttons
        {
          props: { variant: "textLinks" },

          style: {
            borderBottomRightRadius: "10px",
            "&:hover": {
              color: "#874973",
              background: "none",
            },
          },
        },
        // PRODUCTS BUTTONS
        {
          props: { variant: "cartOutsideChange" },
          style: {
            borderRadius: 0,
            display: "block",
            backgroundImage:
              'url("/static/images/buttonBackgrounds/buttonFilledPurple.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            color: "white",
            padding: "14px",
            boxSizing: "border-box",
            width: "100%",
            textTransform: "capitalize",
            overflow: "hidden",
            "& span": {
              opacity: "0",
              display: "inline-block",
              marginRight: "-10px",
              marginLeft: "7px",
              marginTop: "0",
              transition: "0.4s ease all",
              height: "15px",
              width: "15px",
            },
            "& span svg": {
              height: "18px",
              width: "18px",
              top: ".13em",
              position: "relative",
            },
            "&:hover": {
              padding: "14px 14px 14px 9px",
            },
            "&:hover span": {
              opacity: "1",
              marginRight: "-1px",
            },
          },
        },
        {
          props: { variant: "cartOutside+-" },
          style: {
            borderRadius: 0,
            border: "solid 2px #731B36", //secondary.main
            display: "flex",
            color: "#731B36", //secondary.main
            alignItems: "center",
            justifyContent: "center",
            padding: "5%",
            boxSizing: "border-box",
            width: "100%",
            "& span#initialSpan": {
              display: "inline",
              height: "25px",
              width: "25px",
            },
            "& span#hoverSpan": {
              display: "none",
              height: "25px",
              width: "25px",
            },
            "& span svg": {
              margin: "0 auto",
              height: "25px",
              width: "25px",
            },
            "&:hover": {
              backgroundColor: "#731B36", //secondary.main
              color: "white",
            },
            "&:hover span#initialSpan": {
              display: "none",
            },
            "&:hover span#hoverSpan": {
              display: "inline",
            },
          },
        },
        {
          props: { variant: "cartInsideRemove" },
          style: {
            borderRadius: 0,
            border: "none",
            display: "block",
            color: "black",
            padding: "0",
            margin: "0",
            fontWeight: "600",
            fontSize: "1rem",
            boxSizing: "border-box",
            lineHeight: "2rem",
            width: "100%",
            textTransform: "uppercase",
            overflow: "hidden",

            "& span": {
              opacity: "0",
              display: "inline-block",
              marginRight: "-10px",
              marginLeft: "5px",
              marginTop: "0",
              transition: "0.5s ease all",
              height: "15px",
              width: "15px",
            },
            "& span svg": {
              height: "18px",
              width: "18px",
              top: ".13em",
              position: "relative",
            },
            "&:hover": {
              padding: "0 1rem 0 0",
              background: "none",
            },
            "&:hover span": {
              opacity: "1",
              marginRight: "-1px",
            },
          },
        },
        {
          props: { variant: "cartOutside+-" },
          style: {
            borderRadius: 0,
            border: "solid 2px #731B36", //secondary.main
            display: "flex",
            color: "#731B36", //secondary.main
            alignItems: "center",
            justifyContent: "center",
            padding: "4%",
            boxSizing: "border-box",
            width: "100%",
            maxWidth: "100%",
            "& span#initialSpan": {
              display: "inline",
              height: "25px",
              width: "25px",
            },
            "& span#hoverSpan": {
              display: "none",
              height: "25px",
              width: "25px",
            },
            "& span svg": {
              margin: "0 auto",
              height: "25px",
              width: "25px",
            },
            "&:hover": {
              backgroundColor: "#874973", //secondary.main
              color: "white",
            },
            "&:hover span#initialSpan": {
              display: "none",
            },
            "&:hover span#hoverSpan": {
              display: "inline",
            },
          },
        },
        {
          props: { variant: "cartClose" },
          style: {
            borderRadius: 0,
            border: "none",
            display: "block",
            height: "100%",
            width: "40px",
            float: "right",
            color: "black",
            padding: "0",
            margin: "0",
            fontWeight: "600",
            fontSize: "1.4rem",
            boxSizing: "border-box",
            textTransform: "uppercase",
            overflow: "hidden",

            "&:hover": {
              backgroundImage:
                'url("/static/images/buttonBackgrounds/buttonFilledPurple.png")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              color: "white",
            },
          },
        },
      ],
    },
  },
  // Miscellaneous
  shape: {
    borderRadius: 0,
  },
  spacing: 12,
});

export default Theme;
